/**
 * This file is used for setting up and connecting to Prismic.
 */

module.exports = {
  // The domain name of your Prismic repository. This can be found in the URL of
  // your repository.
  prismicRepo: 'hakkei',
  defaultLanguage: 'en-us',
  langs: ['en-us'],
};
